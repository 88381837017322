import {Flex, Button, HStack, Heading } from "@chakra-ui/react"
import { useHistory } from "react-router-dom";
const SiteHeader = () => {
    let history = useHistory()
    const goToSite = () => {
        
        history.push('/about')
    }
    return (
    <Flex justifyContent="space-between" paddingLeft={3} paddingTop={2} paddingRight={4} borderRadius={5} paddingBottom={2} border="1px" borderColor="gray.200">
        <HStack >
            <Heading fontSize={["md","xl","2xl"]}>Tracker Covid</Heading>
            <Button size="sm" onClick={goToSite}>A propos</Button>
            <Button size="sm" marginRight={3} onClick={() => {window.location ='https://anova.ddec.nc'}}>Lycée</Button>
        </HStack>
        <Button onClick={() => {window.location ='https://gouv.nc/info-vaccination/ou-se-faire-vacciner'}}>Se faire vacciner</Button>
    </Flex>
    )
}

export default SiteHeader