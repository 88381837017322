import { useState } from "react";
import * as dayjs from 'dayjs'
import {
    Slider,
    SliderTrack,
    SliderThumb,
    Box,
    useColorModeValue,
    Text,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
  } from "@chakra-ui/react"


const SliderVaccin = (props) => {
    const value = useColorModeValue("27px 27px 54px #ededed, -27px -27px 54px #ffffff;", "2xl")
    const [SliderValue, setSliderValue] = useState(1500);
    const HandleSlider = (e) => {
        setSliderValue(e)
    }
    const calculation75 = () => {
         //https://www.isee.nc/population/demographie
        const LastVaccine = props.data.vaccin_2[0].value
        const fisrtDose = props.data.vaccin_1[0].value - props.data.vaccin_2[0].value
        const value1 = 202400 //75%
        if (value1 - LastVaccine <= 0){ //Si il y a plus de gens vaccinés que demandé
            return 0
        }
        if (value1 - LastVaccine -fisrtDose <= 0) {
            return fisrtDose/SliderValue
        }//Si il y a plus de gens vaccinés que demandé avec la première dose comprise
        
        const toVaccine = value1 -LastVaccine
        const zeroDose = toVaccine - fisrtDose
        return (zeroDose*2+fisrtDose)/SliderValue
    }
    const calculation50 = () => {
        const LastVaccine = props.data.vaccin_2[0].value
        const fisrtDose = props.data.vaccin_1[0].value - props.data.vaccin_2[0].value
        const value1 = 135600 //50%
        if (value1 - LastVaccine <= 0){ //Si il y a plus de gens vaccinés que demandé
            return 0
        }
        if (value1 - LastVaccine -fisrtDose <= 0) {
            return fisrtDose/SliderValue
        }//Si il y a plus de gens vaccinés que demandé avec la première dose comprise
        const toVaccine = value1 -LastVaccine
        const zeroDose = toVaccine - fisrtDose
        return (zeroDose*2+fisrtDose)/SliderValue
    }
    return (
    <Box p={4} boxShadow={value} maxWidth={600} flex="1 1 240px" textAlign="center" marginTop={8}>
    
    
    <Slider value={SliderValue} onChange={HandleSlider}  defaultValue={1500} min={0} max={5000} step={200}>
        <SliderTrack >
        
        </SliderTrack>
        <SliderThumb boxSize={6} />
    </Slider>
        <Text p={2} fontWeight="semibold">{SliderValue + " vaccinations par jour"}</Text>
        <Stat flex="1 1 240px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Date estimée où 50% de la population sera vaccinée à 2 doses</StatLabel>
                    <StatNumber marginBottom={5}>{dayjs().add(calculation50(), 'day').format('DD/MM/YYYY')}</StatNumber>

                    <StatLabel>Date estimée où 75% de la population sera vaccinée à 2 doses</StatLabel>
                    <StatNumber marginBottom={5}>{dayjs().add(calculation75(), 'day').format('DD/MM/YYYY')}</StatNumber>
                    <StatHelpText fontWeight="light" >Ce calcul ne prend pas en compte les 3 semaines de délai entre les deux doses (rajouter environ 3 semaines pour les résultats à moins d'un mois)</StatHelpText>
                    
                    
        </Stat>
    </Box>
    )
}
export default SliderVaccin