import {Box, ChakraProvider, Heading, Text, Link} from "@chakra-ui/react"

const About = () => {
    return(
        <ChakraProvider>
        <Box p={6} textAlign="start" boxShadow="xl" maxWidth={["90%", "80%" ,"50%",] } margin="auto">
            <Heading fontSize={["lg","xl","2xl"]}  margin={4}>Pourquoi ce site ?</Heading>
            <Text p={1} >Le gouvernement ainsi que les médias fournissent un très bon travail pour reporter la situation. Cependant, il n'existe aucun outil qui permet
            de suivre la situation chronologiquement dans le temps. Voilà où intervient cet outil. Il fournit un recul sur les données de la covid-19 tout en amenant
            une meilleure compréhension des statistiques avec des graphiques ou encore une calculatrice</Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>D'où viennent les données ?</Heading>
            <Text p={1}>
                L'historique des données depuis décembre 2019 proviennent de l'agence reuters. Les données récentes (depuis le 6 septembre) proviennent en majeure partie
                du gouvernement de la Nouvelle-Calédonie (site internet + conférence de presse). Le reste provient des Nouvelles Calédoniennes.
            </Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>Où résident ces données</Heading>
            <Text p={1}>
                Les données sont hébergées sur une base de données Airtable. Une API se charge ensuite de les récupérer pour les acheminer à l'utilisateur
            </Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>Collectez-vous des données (cookies ou autres) ?</Heading>
            <Text p={1}>
                Presque pas. Nous avons seulement un script côté serveur qui compte le nombre de pages vues. Pour autant, cette donnée est anonymisée et ne quitte jamais le serveur.
                Elle nous permet de savoir si des utilisateurs sont sur le site. Aucune donnée n'est jamais transmise à des tiers.
            </Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>J'ai constaté une erreur sur les données, un bug/J'ai une question. Puis-je vous contacter ?</Heading>
            <Text p={1}>
                Bien sûr, et nous en serons ravis. Veuillez passer par <Link color="teal.500" href="https://airtable.com/shr0e2cdrXsJqCMus"> ce formulaire </Link> pour que l'on puisse récolter quelques informations. Nous vous recontacterons par mail d'ici peu 
            </Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>Légitimité des données</Heading>
            <Text p={1}>
                Les données présentes ne sont que l'accumulation de recherches et ne sauraient témoigner d'une quelconque forme de vérité. Veuillez ne pas les utiliser pour un usage professionel
            </Text>
            <Heading fontSize={["lg","xl","2xl"]} margin={4}>Question technique (pour les initiés)</Heading>
            <Text p={1}>
                Le site a été conçu en utilisant React. Nous avons utilisé Chakra UI pour la plupart des composants. Enfin, le routeur client utilise react-router-dom.<br />
                Côté serveur, toutes les 30s, un script met en cache les données d'Airtable et les formatte. Ensuite, les données sont servies par Node.JS en utilisant Fastify. 
                Le tout est derrière un reverse proxy apporté par Caddy Server
            </Text>
        </Box>

        </ChakraProvider>
    )
}

export default About