import { Box, Flex, useColorModeValue, Text } from '@chakra-ui/react';
import { Line } from 'britecharts-react';

const Graph = (props) => {
    const margin = {
        top: 60,
        right: 30,
        bottom: 60,
        left: 70,
    };
    const value = useColorModeValue("27px 27px 54px #ededed, -27px -27px 54px #ffffff;", "2xl")
    if (!props.data) {
        return <h1>Chargement</h1>
    }
    else {
        console.log(props.data.nbr_cas_jour);
        let infection = {data:props.data.nbr_cas_jour}
        let reanimation = {data:props.data.nbr_rea}
        let vaccination2 = {data:props.data.vaccin_2}
        let vaccination1 = {data:props.data.vaccin_1}
        return (
            <Flex wrap="wrap" justify="space-around" direction="row" p={2}>
                <Box /* flexBasis={250} */ boxShadow={value} textAlign="center" marginTop={8}>
                <Line margin={margin} aspectRatio={1.3} grid="vertical" lineCurve="basis" height={400} width={320} data={infection} />
                <Text fontWeight="bold" m={4} fontSize={["sm","md"]}>Nouveaux cas par jour</Text>
                </Box>
                <Box /* flexBasis={250} */ boxShadow={value} textAlign="center" marginTop={8}>
                <Line margin={margin} aspectRatio={1.3} grid="vertical" lineCurve="basis" height={400} width={320} data={reanimation} />
                <Text fontWeight="bold" m={4} fontSize={["sm","md"]}  >Patients en réanimation</Text>
                </Box>
                <Box /* flexBasis={250} */ boxShadow={value} textAlign="center" marginTop={8} >
                <Line margin={margin} aspectRatio={1.3} grid="vertical" lineCurve="basis" height={400} width={320} data={vaccination1} />
                <Text fontWeight="bold" m={4} fontSize={["sm","md"]}  >Personnes ayant bénéficié d'une première dose</Text>
                </Box>
                <Box /* flexBasis={250} */ boxShadow={value} textAlign="center" marginTop={8} >
                <Line margin={margin} aspectRatio={1.3} grid="vertical" lineCurve="basis" height={400} width={320} data={vaccination2} />
                <Text fontWeight="bold" m={4} fontSize={["sm","md"]}  >Personnes ayant bénéficié d'une vaccination complète</Text>
                </Box>
            </Flex>
        )
    }
    //Chart nbr cas total
    //Chart Réanimatio
    //Chart Vaccination
    //Nouvelle ligne : chart quand est-ce que la vaccination sera finie
    
}

export default Graph