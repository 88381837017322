import React from 'react';
import {
  ChakraProvider,
  Box,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { Heading } from "@chakra-ui/react"
import useSWR from 'swr'
import NbrCas from './nbr_cas';
import Graph from './Graph'; 
import Vaccin from './vaccin';
import SiteHeader from './SiteHeader'
function App() {
  const fetcher = url => fetch(url).then(r => r.json())
  const { data } = useSWR('https://api.covid.nsi-anova.ml', fetcher)
  const Warning = () => (
  <Alert /* boxShadow="xl" */ colorScheme="cyan" width="50%" status="info">
    <AlertIcon />
    Les données ne représentent pas forcément la réalité
  </Alert>
)

  return (
    <ChakraProvider>
      <SiteHeader />
      <Box /* height="100%" width="100%" */ p={3}>
        <Heading fontSize={["md","xl","2xl"]} p={2}>Suivez les statistiques sur la Covid-19 en Nouvelle Calédonie</Heading>
        <Box maxWidth="1200px"   /* border="3px solid green" */ margin="auto">
        <Tabs marginTop={5} borderRadius="5px" /* background="#e0e0e0" */ boxShadow="xl">
          <TabList fontSize="md">
            <Tab fontSize={["sm", "md","lg"]} >Nombre de cas</Tab>
            <Tab fontSize={["sm", "md","lg"]}>Vaccination</Tab>
            <Tab fontSize={["sm", "md","lg"]}>Graphiques</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <NbrCas data={data} />
              <Warning></Warning>
            </TabPanel>
            <TabPanel>
              <Vaccin data={data} />
            </TabPanel>
            <TabPanel>
              <Graph data={data} />
            </TabPanel>
          </TabPanels>
          
        </Tabs>
        
        </Box>
        

      </Box>
    </ChakraProvider>
  );
}

export default App;
