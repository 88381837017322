import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    useColorModeValue
  } from "@chakra-ui/react"

const Stats1 = (props) => {
    let today = props.data.nbr_cas_jour[props.index].value
    var yesterday
    try {
        yesterday = AssignYesterday()
    }
    catch{ //Puisqu'il me retourne Undefined et crash, je suis obligé d'handle l'erreur et d'assigner la valeur ici puisqu'il n'en est pas capable
        yesterday = 0
    }
    function AssignYesterday () {
        if (!props.data.nbr_cas_jour[props.index+1].value) {
            return 0
        } else {
            return props.data.nbr_cas_jour[props.index+1].value
        }
    }
    const value = useColorModeValue("  27px 27px 54px #ededed, -27px -27px 54px #ffffff;", "2xl")

    return (

        <Stat flex="1 1 180px" p={5} m={5} borderRadius={14} boxShadow={value}>
            <StatLabel>Nouveau cas</StatLabel>
            <StatNumber>{today}</StatNumber>
            <StatHelpText>
            <StatArrow type={today/yesterday*100 > 100 ? "increase":"decrease" } color={today/yesterday*100 > 100 ? "red":"green" } />
            {`${(Math.round(today/yesterday*10)/10*100)} % par rapport au jour d'avant`}
            </StatHelpText>
        </Stat>
    )
}

export default Stats1