//Implémenter un sélecteur de jour !
import * as dayjs from 'dayjs'
import {useState} from 'react';
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Flex
  } from "@chakra-ui/react"
import Stats1 from "./stats_cas";
import { Select, useColorModeValue } from "@chakra-ui/react"

const NbrCas = (props) => {
    //Ajouter conditionnal rendering pour le + ou - dans la 1ere stats


    var somme = 0
    var sommem = 0 //Somme des morts 
    const [DayChosen, setDayChosen] = useState();
    const [ArrayIndex, setArrayIndex] = useState(0);
    function handleChange(e) {
        setDayChosen(e.target.value)
        setArrayIndex(props.data.nbr_cas_jour.findIndex(f => f.date === e.target.value))
        console.log(DayChosen)
        console.log(ArrayIndex);
        
    }
    const value = useColorModeValue("27px 27px 54px #ededed, -27px -27px 54px #ffffff;", "2xl")
    if (!props.data) {
        return <h1>Chargement...</h1>
    }
    else {
        const ListSelect = props.data.nbr_cas_jour.map((n) => 
            <option value={n.date}>{dayjs(n.date).format('DD/MM/YYYY')}</option>
        )
        for (let index = ArrayIndex; index < props.data.nbr_cas_jour.length; index++) {
            const element = props.data.nbr_cas_jour[index];
            const nbr = element.value
            somme = somme + nbr
            
        }
        for (let index = ArrayIndex; index < props.data.nbr_deces.length; index++) {
            const element = props.data.nbr_deces[index];
            const nbr = element.value
            sommem = sommem + nbr
            
        }
        
        return (
            <Flex wrap="wrap" justify="space-around" direction="row">
                <Select onChange={handleChange} value={DayChosen} >
                    {ListSelect}
                </Select>
                <Stats1 data={props.data} index={ArrayIndex} />
                <Stat  flex="1 1 180px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Cas</StatLabel>
                    <StatNumber>{somme}</StatNumber>
                    <StatHelpText>Depuis le 6 septembre 2021</StatHelpText>
                </Stat>
                <Stat flex="1 1 180px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>En réanimation</StatLabel>
                    <StatNumber>{props.data.nbr_rea[ArrayIndex].value}</StatNumber>
                    <StatHelpText>Au jour le jour</StatHelpText>
                </Stat>
                <Stat flex="1 1 180px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Mort(s)</StatLabel>
                    <StatNumber>{props.data.nbr_deces[ArrayIndex].value}</StatNumber>
                    <StatHelpText>Depuis le 6 septembre 2021</StatHelpText>
                </Stat>
            </Flex>
        )
    }
}

export default NbrCas