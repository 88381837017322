import {Divider, Flex, Stat, StatArrow, StatNumber, StatLabel, StatHelpText, Select, useColorModeValue } from "@chakra-ui/react"
import { useState } from "react";
import * as dayjs from 'dayjs'
import SliderVaccin from "./SliderVaccin";

const Vaccin = (props) => {

    const [DayChosen, setDayChosen] = useState();
    const [ArrayIndex, setArrayIndex] = useState(0);
    function yValue2 () {
        try {
            return props.data.vaccin_2[ArrayIndex+1].value || 0
        } catch  {
            return 0
        }   
    }
    function yValue1 () {
        try {
            return props.data.vaccin_1[ArrayIndex+1].value || 0
        } catch  {
            return 0
        }   
    }

    function handleChange(e) {
        setDayChosen(e.target.value)
        const index = props.data.nbr_cas_jour.findIndex(f => f.date === e.target.value)
        setArrayIndex(index)
        
    }
    const value = useColorModeValue("27px 27px 54px #ededed, -27px -27px 54px #ffffff;", "2xl")
   

    if (!props.data) {
        return <h1>Chargement...</h1>
    } else {
        const ListSelect = props.data.nbr_cas_jour.map((n) => 
            <option value={n.date}>{dayjs(n.date).format('DD/MM/YYYY')}</option>
        )
        return (
            <Flex wrap="wrap" justify="space-around" direction="row">
            <Select onChange={handleChange} value={DayChosen} >
                {ListSelect}
            </Select>
            <Stat flex="1 1 240px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Personnes vaccinées (1ere dose) </StatLabel>
                    <StatNumber>{props.data.vaccin_1[ArrayIndex].value}</StatNumber>
                    <StatHelpText>
                        <StatArrow type="increase" />
                        {props.data.vaccin_1[ArrayIndex].value - yValue1()}
                    </StatHelpText>
            </Stat>
            <Stat flex="1 1 240px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Personnes vaccinées (2eme dose) </StatLabel>
                    <StatNumber>{props.data.vaccin_2[ArrayIndex].value}</StatNumber>
                    <StatHelpText>
                        <StatArrow type="increase" />
                        {props.data.vaccin_2[ArrayIndex].value  - yValue2()}
                    </StatHelpText>
                    
            </Stat>
            <Stat flex="1 1 240px" p={5} m={5} borderRadius={14} boxShadow={value}>
                    <StatLabel>Pourcentage population totalement vaccinées</StatLabel>
                    <StatNumber>{Math.round(props.data.vaccin_2[ArrayIndex].value/271200*100 *100)/100 + " %"}</StatNumber> {/* //Pour arrondir aux deux premiers nombres après la virgule */}
                    <StatHelpText> {/* //https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary */}
                        {Math.round(props.data.vaccin_1[ArrayIndex].value/271200*100 *100)/100 + " % (une seule dose)" }
                    </StatHelpText>
                    
            </Stat>
            <Divider />
            <SliderVaccin data={props.data} />
            
        </Flex>
        )
    }
}

export default Vaccin